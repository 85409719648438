import React, { useState } from "react";
import "./App.scss";
import "boxicons/css/boxicons.min.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import News from "./pages/News";
import StudBookAr from "./pages/StudBookAr";
import StudBookPs from "./pages/StudBookPs";
import Catalogue from "./pages/catalogue_de_vente_annuelle";
import CatalogueE from "./pages/catalogue_etalons";
import CatalogueVM from "./pages/catalogue_de_vente_mixte";
import Cahiers from "./pages/cahiers_de_charge_et_conventions";
import Appels from "./pages/appels_d_offres";
import Concours from "./pages/concours_d_elvage";
import Consultations from "./pages/consultations";
import Agenda from "./pages/agenda";
import Annonces from "./pages/Annonces";
import Autres from "./pages/autres";
import Procedures from "./pages/procedures";
import Login from "./pages/Login";
import Imprimes from "./pages/imprimes_en_ligne";
import Add from "./pages/Add";
import Responsables from "./pages/Responsables";
import Rapports from "./pages/Rapports";
import TextelegistatifA from "./pages/textelegistatif_administratif";
import TextelegistatifD from "./pages/textelegistatif_dopage";
import TextelegistatifE from "./pages/textelegistatif_elevage";
import Manuel from "./pages/Manuel";
import Demande from "./pages/Demande";
import References from "./pages/References";
import Mediatheque from "./pages/Mediatheque";
import Politique from "./pages/Politique";
import Update from "./pages/Update";
function App() {
  const [currentUser, setCurrentUser] = useState(false);
  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <RequireAuth>
                <News setCurrentUser={setCurrentUser} />
              </RequireAuth>
            }
          />
          <Route path="/news/">
            <Route
              index
              element={
                <RequireAuth>
                  <News setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/studbookar/">
            <Route
              index
              element={
                <RequireAuth>
                  <StudBookAr setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/Mediatheque/">
            <Route
              index
              element={
                <RequireAuth>
                  <Mediatheque setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/textelegistatif_administratif/">
            <Route
              index
              element={
                <RequireAuth>
                  <TextelegistatifA setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/textelegistatif_dopage/">
            <Route
              index
              element={
                <RequireAuth>
                  <TextelegistatifD setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/textelegistatif_elevage/">
            <Route
              index
              element={
                <RequireAuth>
                  <TextelegistatifE setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/catalogue_etalons/">
            <Route
              index
              element={
                <RequireAuth>
                  <CatalogueE setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/catalogue_de_vente_mixte/">
            <Route
              index
              element={
                <RequireAuth>
                  <CatalogueVM setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/autres/">
            <Route
              index
              element={
                <RequireAuth>
                  <Autres setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/imprimes_en_ligne/">
            <Route
              index
              element={
                <RequireAuth>
                  <Imprimes setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/catalogue_de_vente_annuelle/">
            <Route
              index
              element={
                <RequireAuth>
                  <Catalogue setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/studbookps/">
            <Route
              index
              element={
                <RequireAuth>
                  <StudBookPs setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/agenda/">
            <Route
              index
              element={
                <RequireAuth>
                  <Agenda setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/cahiers_de_charge_et_conventions/">
            <Route
              index
              element={
                <RequireAuth>
                  <Cahiers setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/appels_d_offres/">
            <Route
              index
              element={
                <RequireAuth>
                  <Appels setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/consultations/">
            <Route
              index
              element={
                <RequireAuth>
                  <Consultations setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/concours_d_elvage/">
            <Route
              index
              element={
                <RequireAuth>
                  <Concours setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/procedures/">
            <Route
              index
              element={
                <RequireAuth>
                  <Procedures setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/Annonces/">
            <Route
              index
              element={
                <RequireAuth>
                  <Annonces setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>

          <Route path="/Responsables/">
            <Route
              index
              element={
                <RequireAuth>
                  <Responsables setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/Rapports/">
            <Route
              index
              element={
                <RequireAuth>
                  <Rapports setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/Manuel/">
            <Route
              index
              element={
                <RequireAuth>
                  <Manuel setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/Demande/">
            <Route
              index
              element={
                <RequireAuth>
                  <Demande setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/References/">
            <Route
              index
              element={
                <RequireAuth>
                  <References setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/Politique/">
            <Route
              index
              element={
                <RequireAuth>
                  <Politique setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="add"
              element={
                <RequireAuth>
                  <Add setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
            <Route
              path="update/:id"
              element={
                <RequireAuth>
                  <Update setCurrentUser={setCurrentUser} />
                </RequireAuth>
              }
            />
          </Route>
          <Route
            path="/login"
            element={<Login setCurrentUser={setCurrentUser} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
