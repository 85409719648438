import React, { useState, useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { db } from "../firebase";
import { collection, setDoc, doc, getDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
const Add = ({ setCurrentUser }) => {
  const [data, setData] = useState({
    titreFr: "",
    titreAr: "",
    descriptionAr: "",
    descriptionFr: "",
    img: "",
    img2: "",
  });

  const navigate = useNavigate();
  const curPath = window.location.pathname.split("/")[1];
  let { id } = useParams();
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const docRef = doc(db, curPath, id);
  const fetchPost = async () => {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setData(docSnap.data());
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);
  const changeHandler = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, curPath, id), data);
      navigate(`/${curPath}`);
    } catch (e) {
      alert("Error editing document: ", e);
    }
  };

  return (
    <div
      style={{
        padding: "50px 0px 0px 370px",
      }}
    >
      <Sidebar setCurrentUser={setCurrentUser} />
      <main>
        <h1>Modifier {data.titreFr} </h1>
        <>
          <Card>
            <Card.Body>
              <Form onSubmit={submitHandler}>
                <Form.Group>
                  <Form.Label>Titre Français</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={changeHandler}
                    name="titreFr"
                    required
                    value={data.titreFr}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Titre Arabe</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={changeHandler}
                    name="titreAr"
                    value={data.titreAr}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description Français</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={changeHandler}
                    as="textarea"
                    rows={3}
                    name="descriptionFr"
                    value={data.descriptionFr}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description Arabe</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={changeHandler}
                    as="textarea"
                    rows={3}
                    name="descriptionAr"
                    value={data.descriptionAr}
                  ></Form.Control>
                </Form.Group>
                <Button
                  type="submit"
                  className="w-100 mt-2"
                  onSubmit={submitHandler}
                >
                  Save
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </>
      </main>
    </div>
  );
};

export default Add;
