import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const Tablee = ({ section, data, setData }) => {
  const [name, setName] = useState("");
  const deleteRow = async (e) => {
    setData([...data.filter((el) => e !== el.id)]);
    const reference = doc(db, section, e);
    await deleteDoc(reference);
  };
  const [twoFiles, setTwoFiles] = useState(false);
  const curPath = window.location.pathname.split("/")[1];
  useEffect(() => {
    if (
      [
        "textelegistatif_elevage",
        "textelegistatif_administratif",
        "procedures",
        "Annonces",
        "news",
        "imprimes_en_ligne",
        "cahiers_de_charge_et_conventions",
        "appels_d_offres",
        "consultations",
        "concours_d_elvage",
        "Politique",
        "References",
        "Demande",
        "Manuel",
        "Rapports",
        "Responsables",
      ].includes(section)
    ) {
      setTwoFiles(true);
    }
    switch (section) {
      case "studbookar":
        setName("StudBook Ar");
        break;
      case "studbookps":
        setName("StudBook Ps");
        break;
      case "textelegistatif_elevage":
        setName("textes legeslatifs elevage");
        break;
      case "textelegistatif_administratif":
        setName("textes legeslatifs administratifs");
        break;
      case "procedures":
        setName(" procedures");
        break;
      case "Annonces":
        setName("Annonces");
        break;
      case "imprimes_en_ligne":
        setName("imprimes en ligne");
        break;
      case "cahiers_de_charge_et_conventions":
        setName("cahiers de charge et conventions");
        break;
      case "appels_d_offres":
        setName("appels d'offres");
        break;
      case "consultations":
        setName("consultations");
        break;
      case "concours_d_elvage":
        setName("concours d'elvage");
        break;
      case "Politique":
        setName("Politique");
        break;
      case "References":
        setName("References");
        break;
      case "Demande":
        setName("Demande");
        break;
      case "Manuel":
        setName("Manuel");
        break;
      case "Rapports":
        setName("Rapports");
        break;
      case "Responsables":
        setName("Responsables");
        break;
      default:
        setName("Actualités");
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "95vw",
          marginBottom: "2rem",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <h1>{name}</h1>
        <Link
          className="btn btn-primary d-f"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
          to={`/${section}/add`}
        >
          Ajouter
        </Link>
      </div>
      <TableContainer
        style={{ width: "95vw", marginLeft: "auto", marginRight: "auto" }}
        component={Paper}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Titre FR</StyledTableCell>
              <StyledTableCell align="center">Titre Ar</StyledTableCell>
              <StyledTableCell align="center">File 1</StyledTableCell>
              {twoFiles && (
                <StyledTableCell align="center">File 2</StyledTableCell>
              )}
              <StyledTableCell align="center">Description Fr</StyledTableCell>
              <StyledTableCell align="center">Description Ar</StyledTableCell>
              <StyledTableCell align="center">Supprimer</StyledTableCell>
              <StyledTableCell align="center">Modifier</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.titreFr}
                </StyledTableCell>
                <StyledTableCell align="center">{row.titreAr}</StyledTableCell>
                <StyledTableCell align="center">
                  <a href={row.img} target="_blank">
                    visualiser
                  </a>
                </StyledTableCell>
                {twoFiles && (
                  <StyledTableCell align="center">
                    <a href={row.img2} target="_blank">
                      visualiser
                    </a>
                  </StyledTableCell>
                )}
                <StyledTableCell align="center">
                  {row.descriptionFr}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.descriptionAr}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    fontSize: "1.5rem",
                    color: "#e30000",
                    cursor: "pointer",
                  }}
                  onClick={() => deleteRow(row.id)}
                  name={row.id}
                >
                  <i class="bx bxs-message-alt-x"></i>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    fontSize: "1.5rem",
                    color: "#557C55",
                    cursor: "pointer",
                  }}
                >
                  <Link to={`/${curPath}/update/${row.id}`}>
                    <EditIcon />
                  </Link>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Tablee;
