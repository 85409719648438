import React, { useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Signin({ setCurrentUser }) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const [waiting, setWaiting] = useState(false);
  const navigate = useNavigate();
  const verbose = true;

  async function submitHandler(e) {
    e.preventDefault();
    setWaiting(true);

    try {
      signInWithEmailAndPassword(
        auth,
        emailRef.current.value,
        passwordRef.current.value
      ).then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate("/news");
        setCurrentUser(user);
      });
    } catch (err) {
      setError(verbose ? err.message : "Failed created the account");
    }
    setWaiting(false);
  }

  return (
    <>
      <Card>
        <Card.Body>
          <h3 className="text-center mb-2">Sign In</h3>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={submitHandler}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required></Form.Control>
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                required
              ></Form.Control>
            </Form.Group>
            <Button type="submit" className="w-100 mt-2" disabled={waiting}>
              Sign In
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
