import React,{useState, useEffect} from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { storage, db } from '../firebase';
import imageCompression from 'browser-image-compression';

import {
        ref,
        uploadBytesResumable ,
        getDownloadURL 
    } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
const Add = ({setCurrentUser}) => {
    const navigate = useNavigate() ;
    const curPath = window.location.pathname.split('/')[1];
    const [file, setImage] = useState('')
    const [twoFiles, setTwoFiles] = useState(false)
    const [data, setData] = useState({
        titreFr:'',
        titreAr:'',
        descriptionAr:'',
        descriptionFr:'',
        img:'',
        img2:'',
    })
    const [percent, setPercent] = useState(0);
    const [percent2, setPercent2] = useState(0);
    let test = false
    useEffect(()=>{
     if(twoFiles){
            test =  percent2 === 100 
        }
    },[percent2, twoFiles])
    
    useEffect(()=>{
        
        if([ 'textelegistatif_elevage', 'textelegistatif_administratif', 'procedures', 'Annonces', 'news' , 'imprimes_en_ligne','cahiers_de_charge_et_conventions','appels_d_offres','consultations','concours_d_elvage' , 'Politique', 'References', 'Demande' , 'Manuel','Rapports','Responsables' ].includes(curPath)){
            setTwoFiles(true)
        }
        if(twoFiles){
               test =  percent2 === 100 
        }else{
            test = true
        }
    }, [])



    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }




    const storageRef = ref(storage, `/files/${file.name}${getRandomInt(10000000000)}`);

    const upload = async (e)=>{
        if (!file) {
                        alert("Please upload a file first!");
            return ;
                    }
             
             
                    // progress can be paused and resumed. It also exposes progress updates.
                    // Receives the storage reference and the file to upload.
                    const uploadTask = uploadBytesResumable(storageRef, file);
             
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const percent = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
             
                            // update progress
                           e === 'img' ? setPercent(percent) : setPercent2(percent)
                        },
                        (err) => console.log(err),
                        () => {
                            // download url
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                setData({...data, [e]:url})
                            });
                        }
                    );
            
        
    }
    const fileHandler = async (e)=>{
        e.preventDefault();
         const imageFile = e.target.files[0];

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920
    }
    let compressedFile 
    if(imageFile.type.includes('pdf')){
        setImage(imageFile)
        return
    }
    try {
        compressedFile = await imageCompression(imageFile, options);
        alert('compression done',compressedFile.size/1024/1024);
    } catch (error) {
        alert(error);
    }
        setImage(compressedFile)
    }
    
    const changeHandler= (e)=>{
        e.preventDefault()
        setData({...data , [e.target.name]:e.target.value})
    }
    const submitHandler = async (e)=>{
        e.preventDefault();
        if( !percent && !test){
            alert('upload files')
            return;
        }
        try {
            const docRef = await addDoc(collection(db, curPath), data);
            navigate(`/${curPath}`)
          } catch (e) {
            alert("Error adding document: ", e);
          }
    }
    
    return (
             <div style={{
            padding: '50px 0px 0px 370px'
        }}>
            <Sidebar setCurrentUser={setCurrentUser}/>
            <main>
                <h1>Ajouter {curPath}</h1>
                <>
                <Card>
                    <Card.Body>
                        <Form onSubmit={submitHandler}>
                            <Form.Group>
                            <Form.Label>Titre Français</Form.Label>
                            <Form.Control type='text' onChange={changeHandler} name="titreFr" required></Form.Control>
                            </Form.Group>
                            <Form.Group>
                            <Form.Label>Titre Arabe</Form.Label>
                            <Form.Control type='text' onChange={changeHandler} name="titreAr" required></Form.Control>
                            </Form.Group>
                            <Form.Group>
                            <Form.Label>Description Français</Form.Label>
                            <Form.Control type='text' onChange={changeHandler} as="textarea" rows={3} name="descriptionFr" ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                            <Form.Label>Description Arabe</Form.Label>
                            <Form.Control type='text' onChange={changeHandler} as="textarea" rows={3} name="descriptionAr" ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                            <Form.Label>File 1</Form.Label>
                            <div style={{display:'flex', gap:'1rem', justifyContent:"center", alignItems:"center"}}>
                                <Form.Control style={{width:'80%'}} type='file' onChange={fileHandler} required></Form.Control>
                                <Form.Label>{percent}</Form.Label>
                                <Button className='w-30 mt-2' onClick={()=>{upload("img")}} >upload</Button>
                            </div>
                            </Form.Group>
                            {twoFiles && <Form.Group>
                            <Form.Label>File 2</Form.Label>
                            <div style={{display:'flex', gap:'1rem', justifyContent:"center", alignItems:"center"}}>
                                <Form.Control style={{width:'80%'}} type='file' onChange={fileHandler}></Form.Control>
                                <Form.Label>{percent2}</Form.Label>
                                <Button className='w-30 mt-2' onClick={()=>{upload('img2')}} >upload</Button>
                            </div>
                            </Form.Group>}
                            <Button type='submit' className='w-100 mt-2' onSubmit={submitHandler} disabled={percent !== 100 && test }>Save</Button>
                        </Form>
                    </Card.Body>
                </Card>
                </>
            </main>
        </div>
    );
}

export default Add;
