import React, { useState, useEffect } from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import Table from '../components/Table';
import { collection, getDocs } from "firebase/firestore";
import {db} from '../firebase';

const News = ({setCurrentUser}) => {
    const [data, setData] = useState()
    const fetchPost = async () => {
       
        await getDocs(collection(db, "studbookar"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                setData(newData);                
                console.log(data, newData);
            })
       
    }
   
    useEffect(()=>{
        fetchPost();
    }, [])
    return (
        <div style={{
            padding: '50px 0px 0px 370px'
        }}>
            <Sidebar setCurrentUser={setCurrentUser}/>
            <main>
                <Table data={data} setData={setData} section="studbookar"/>
            </main>
        </div>
    );
}

export default News;
