import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './sidebar.scss';

const sidebarNavItems = [
    {
        display: 'Actualités',
        icon: <i class='bx bx-news'></i>,
        to: '/news',
        section: 'news'
    },
    {
        display: 'StudBook Ps',
        icon: <i class='bx bx-news'></i>,
        to: '/studbookps',
        section: 'studbookps'
    },
    {
        display: 'StudBook Ar',
        icon: <i class='bx bx-news'></i>,
        to: '/studbookar',
        section: 'studbookar'
    },
   
    {
        display: 'Agenda',
        icon: <i class='bx bx-news'></i>,
        to: '/agenda',
        section: 'agenda'
    },
    {
        display: 'Catalogue de vente annuelle',
        icon: <i class='bx bx-news'></i>,
        to: '/catalogue_de_vente_annuelle',
        section: 'catalogue_de_vente_annuelle'
    },
    {
        display: 'Catalogue etalons',
        icon: <i class='bx bx-news'></i>,
        to: '/catalogue_etalons',
        section: 'catalogue_etalons'
    },
    {
        display: 'Catalogue de vente mixte',
        icon: <i class='bx bx-news'></i>,
        to: '/catalogue_de_vente_mixte',
        section: 'catalogue_de_vente_mixte'
    },
    {
        display: 'Autres',
        icon: <i class='bx bx-news'></i>,
        to: '/autres',
        section: 'autres'
    },
    {
        display: 'Imprimes en ligne',
        icon: <i class='bx bx-news'></i>,
        to: '/imprimes_en_ligne',
        section: 'imprimes_en_ligne'
    },
    {
        display: 'Cahiers de charge et Conventions',
        icon: <i class='bx bx-news'></i>,
        to: '/cahiers_de_charge_et_conventions',
        section: 'cahiers_de_charge_et_conventions'
    },
    {
        display: "Appels d'offres",
        icon: <i class='bx bx-news'></i>,
        to: '/appels_d_offres',
        section: 'appels_d_offres'
    },
    {
        display: "Consultations",
        icon: <i class='bx bx-news'></i>,
        to: '/consultations',
        section: 'consultations'
    },
    {
        display: "Concours d'elvage",
        icon: <i class='bx bx-news'></i>,
        to: '/concours_d_elvage',
        section: 'concours_d_elvage'
    },
    {
        display: "Procedures",
        icon: <i class='bx bx-news'></i>,
        to: '/procedures',
        section: 'procedures'
    },
    {
        display: "Annonces",
        icon: <i class='bx bx-news'></i>,
        to: '/Annonces',
        section: 'Annonces'
    },
    {
        display: "Responsables de l’accès à l’information",
        icon: <i class='bx bx-news'></i>,
        to: '/Responsables',
        section: 'Responsables'
    },
    {
        display: "Rapports",
        icon: <i class='bx bx-news'></i>,
        to: '/Rapports',
        section: 'Rapports'
    },
    {
        display: " Manuel des procédures",
        icon: <i class='bx bx-news'></i>,
        to: '/Manuel',
        section: 'Manuel'
    },
    {
        display: "Demande d’accès aux documents",
        icon: <i class='bx bx-news'></i>,
        to: '/Demande',
        section: 'Demande'
    },
    {
        display: "Références juridiques",
        icon: <i class='bx bx-news'></i>,
        to: '/References',
        section: 'References'
    },
    {
        display: "Politique d’accès à l’information",
        icon: <i class='bx bx-news'></i>,
        to: '/Politique',
        section: 'Politique'
    },
    {
        display: "Textes législatifs réglementant",
        icon: <i class='bx bx-news'></i>,
        to: '/textelegistatif_administratif',
        section: 'textelegistatif_administratif'
    },
    {
        display: "Textes juridiques relatifs à la lutte contre le dopage dans le sport",
        icon: <i class='bx bx-news'></i>,
        to: '/textelegistatif_dopage',
        section: 'textelegistatif_dopage'
    },
    {
        display: "Textes legistatif liés à l'élevage ",
        icon: <i class='bx bx-news'></i>,
        to: '/textelegistatif_elevage',
        section: 'textelegistatif_elevage'
    },
    
   {
       display: "Médiathèque ",
       icon: <i class='bx bx-news'></i>,
       to: '/Mediatheque',
       section: 'Mediatheque'
   },
   
]

const Sidebar = ({setCurrentUser}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [stepHeight, setStepHeight] = useState(0);
    const sidebarRef = useRef();
    const indicatorRef = useRef();
    const location = useLocation();
    const [active , setActive] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            const sidebarItem = sidebarRef.current.querySelector('.sidebar__menu__item');
            indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
            setStepHeight(sidebarItem.clientHeight);
        }, 50);
    }, []);

    // change active index
    useEffect(() => {
        const curPath = window.location.pathname.split('/')[1];
        const activeItem = sidebarNavItems.findIndex(item => item.section === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    return(<>
    <div className={active ?'hamburger active' :'hamburger'} onClick={()=>{setActive(!active)}}>
        <div className='bar1'></div>
        <div className='bar2'></div>
        <div className='bar3'></div>
    </div>
    <div className={ active === true ? "sidebar active" :"sidebar"}>
        <div className="sidebar__logo">
            FNARC
        </div>
        <div ref={sidebarRef} className="sidebar__menu">
            <div
                ref={indicatorRef}
                className="sidebar__menu__indicator"
                style={{
                    transform: `translateX(-50%) translateY(${activeIndex * stepHeight}px)`
                }}
            ></div>
            {
                sidebarNavItems.map((item, index) => (
                    <Link to={item.to} key={index}>
                        <div className={`sidebar__menu__item ${activeIndex === index ? 'active' : ''}`}>
                            <div className="sidebar__menu__item__icon">
                                {item.icon}
                            </div>
                            <div className="sidebar__menu__item__text">
                                {item.display}
                            </div>
                        </div>
                    </Link>
                ))
            }
        </div>
            <li onClick={()=>{setCurrentUser(false)}} style={{color:'white', backgroundColor:'red', cursor:'pointer', border:'2px solid white', listStyle:'none', fontWeight:"bold", textAlign:'center', padding:'1rem 2rem', borderRadius:'10px', width:"90%" , marginInline:'auto', marginTop:'auto'}}>déconnexion</li>
    </div></>)
};

export default Sidebar;
